@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:wght@100;400;700&family=Lato:wght@400;700&family=Poppins:wght@100;400;800&display=swap');
@import 'bootstrap/dist/css/bootstrap.min.css';
* {
  box-sizing: border-box;
}

:root {
  --theme-text: #2d3748;
  --theme-text-dull: #5c6069;
  --theme-text-long: #718096;
  --theme-color: #0081eb;
  --theme-color-hover: #0062b3;
  --theme-section-bg: #f6f7fc;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Albert Sans', 'Poppins';
  color: var(--theme-text);
  text-decoration: none;
  list-style: none;
  background-color: black;
  /* overflow-x: hidden; */
}

html {
  font-size: 10px;
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  max-width: 144rem;
  min-width: 30rem;
  margin: 0 auto 0 auto;
  background-color: #3f3f3f;
}
